let origin = "https://future.zhuneng.cn";
//众创空间列表
const createWorkUrl = `/gateway/hc-external/makerSpace/getApplyList`;

//众创空间申请
const applyCreateWorkList = `/gateway/hc-external/makerSpace/doApply`;
//详情
const createWorkDetail = `/gateway/hc-space/space/detail`;
//众创空间列表
const workAreaListUrl = `/gateway/hc-space/space/list`;
//状态变更
const updateStatusUrl = `/gateway/hc-space/space/update`;
export {
  createWorkUrl,
  applyCreateWorkList,
  createWorkDetail,
  workAreaListUrl,
  updateStatusUrl,
};
